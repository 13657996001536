import { Routes, Route} from 'react-router-dom';
import React from 'react';
import { Homepage } from './pages/homepage/Homepage';
import { Season1 } from './components/Season1';
import { Notfoundpage } from './pages/Notfoundpage';
import Layout from './components/Layout';

import Season1Page from './pages/video/Season1Page';
import {Seasn2}  from './components/Seasn2.jsx';
import Season2Page from './pages/video/Season2Page';
import { SesProb2 } from './components/SesProb2';
import { Kar } from './components/karusel/Kar';
import 'bootstrap/dist/css/bootstrap.min.css';  // Импорт стилей Bootstrap

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Homepage />} />
          <Route path='/season_1' element={<Season1/>} />
          <Route path='/season_1/:id' element={<Season1Page/>} />
          <Route path='/season_2' element={<Seasn2/>} />
          {/* <Route path='/season_2' element={<SesProb2/>} /> */}
          {/* <Route path='/season_2' element={<Kar/>} /> */}
          <Route path='/season_2/:id' element={<Season2Page/>} />
          <Route path='*' element={<Notfoundpage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
