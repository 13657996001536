import React from 'react';
import {Outlet,useLocation } from 'react-router-dom';
import { Container,Row,Col,Button} from 'react-bootstrap';
//import Abrhead from '../pages/header/Abrhead';
import NavigationBar from '../pages/header/NavigationBar';
import Reklama from '../pages/homepage/Reklama';
import MyCarousel from './karusel/MyCarousel';
function Layout() {
  const location = useLocation();
  // Условие для скрытия правого блока для '/season_1' и '/season_2'
  //const hideRightBlock = location.pathname === '/season_1' || location.pathname === '/season_2';
  const hideRightBlock = location.pathname === '/season_1' || location.pathname === '/season_2' || 
    location.pathname.match(/^\/season_1\/\d+$/) || location.pathname.match(/^\/season_2\/\d+$/);
  return (
    <>
      <NavigationBar /> {/* Добавьте Navbar сюда */}
      <Container fluid >
          {!hideRightBlock && (
          <>
           <MyCarousel/>
          </>
          )}
       <Row>
         <Col xs={12} md={hideRightBlock ? 12 : 8}>
          <main>
            <Outlet/>
          </main>
         </Col>
         {!hideRightBlock && (
         <Col xs={6} md={4}>{/* Правый блог*/}
           <Button onClick={() => alert('Button Clicked!')}>Click Mess</Button>
           <Reklama/> 
            {/* Другие интерактивные элементы */}
         </Col>
         )}
       </Row>
      </Container>
      <footer className="bg-dark text-white mt-5 p-4 text-center">
          <h2>
              footer
          </h2>
      </footer>  
    </>
  )
}

export default Layout
